<template>
  <ion-button
    :class="appButtonClass"
    :style="appButtonStyle"
    mode="md"
    :props="$props"
    :fill="fill"
    data-testid="app-button"
  >
    <ion-spinner
      v-if="isLoading"
      name="crescent"
      data-testid="app-button-spinner"
    />
    <template v-else>
      <router-link v-if="to" :to="to" :replace="replaceRoute">
        <slot
          ><span>{{ label }}</span></slot
        >
      </router-link>
      <slot v-else
        ><span>{{ label }}</span></slot
      >
    </template>
  </ion-button>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { IonButton, IonSpinner } from '@ionic/vue';
import { AppButtonType } from '@/shared/types/components';

export default defineComponent({
  name: 'AppButton',

  components: {
    IonButton,
    IonSpinner,
  },

  props: {
    label: {
      type: String,
      default: undefined,
    },
    textColor: {
      type: String,
      default: undefined,
    },
    backgroundColor: {
      type: String,
      default: undefined,
    },
    type: {
      type: String as PropType<AppButtonType>,
      default: 'filled',
    },
    to: {
      type: [Object, String],
      default: undefined,
    },
    replaceRoute: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    fill: {
      type: String as PropType<
        'default' | 'clear' | 'outline' | 'solid' | undefined
      >,
      default: 'default',
    },
  },

  computed: {
    appButtonClass(): unknown {
      return [
        'app-button',
        `app-button--${this.type}`,
        { 'app-button--loading': this.isLoading },
      ];
    },

    appButtonStyle(): unknown {
      return { color: this.textColor, '--background': this.backgroundColor };
    },
  },
});
</script>

<style scoped lang="scss">
.app-button {
  font-size: 17px;
  font-weight: 700;
  line-height: 150%;
  --box-shadow: none;
  text-transform: none;
  --border-radius: 24px;
  --border-width: 1px;
  letter-spacing: 0.02em;
  flex-shrink: 0;
}

.app-button--filled {
  color: $white;
  --background: #{$blue-500};
}

.app-button--filled ion-spinner {
  --color: #{$white};
}

.app-button--vaibe {
  color: $white;
  --background: #{$vaibe-gradient-dark};
}

.app-button--vaibe ion-spinner {
  --color: #{$white};
}

.app-button--empty {
  --background: transparent;
  color: $blue-500;
}

.app-button--reverse-vaibe {
  --background: #{$white};
  color: $violet-500;
}

.app-button--reverse-empty {
  --background: transparent;
  color: $white;
}

.app-button--vaibe-dark-mode {
  --background: #{$white};
  color: $white;
}

.app-button--vaibe-dark-mode:deep(*) {
  background: $vaibe-gradient-dark;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.5;
}

.app-button--empty ion-spinner {
  --color: #{$blue-500};
}

.app-button--loading {
  pointer-events: none;
}
</style>
