import { PostHogOperator } from '@/shared/types/posthog';
import useTokenStore from '@/store/token/useTokenStore';
import useNativeSettingsStore from '@/store/nativeSettings/useNativeSettingsStore';
import { usePostHog } from '@/plugins/commons';

export default function usePostHogEvents() {
  const { orgId, b2cId } = useTokenStore();
  const { deviceModel, deviceName, devicePlatformVersion } =
    useNativeSettingsStore();

  const postHogParams: PostHogOperator = {
    orgId,
    b2cId,
    deviceModel,
    deviceName,
    devicePlatformVersion,
  };
  const postHog = usePostHog(postHogParams);

  return postHog;
}
