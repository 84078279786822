import { CollectableTypeName } from '@/shared/types/profile';
import { ApiResponse, ResponseMetadata } from '@/shared/types/generic';
import {
  CategorizedOption,
  CollectablesResponse,
  ItemOrigin,
  PurchaseStatus,
  PurchasedItem,
  StoreItem,
} from '@/shared/types/store';
import { placeholderImage } from '@/shared/constants/icons';
import { random } from 'lodash';
import { getRandomNumber } from '@/plugins/commons';

const metadata: ResponseMetadata = {
  totalItems: 20,
  lastUpdated: 1650290653000,
};

function getRandomArbitrary(min: number, max: number) {
  return Math.floor(Math.random() * (max - min) + min);
}

export const getStoreItemList = (
  limit: number,
  type: CollectableTypeName | string,
): StoreItem[] => {
  return new Array(limit).fill(0).map((_, index) => ({
    reference: String(index),
    id: index,
    name: `StoreItem-${index}`,
    description: '',
    url: placeholderImage,
    price: getRandomArbitrary(1, 100000),
    tagName:
      type === CollectableTypeName.AVATAR ||
      type === CollectableTypeName.BACKGROUND
        ? 'item.tag.originals'
        : 'custom category',
    type: {
      reference: index.toString(),
      name: type,
    },
    purchased: false,
    stockNumber: 10,
  }));
};

export const getAvatarList = (limit: number): StoreItem[] =>
  getStoreItemList(limit, CollectableTypeName.AVATAR);

export const getBackgroundList = (limit: number): StoreItem[] =>
  getStoreItemList(limit, CollectableTypeName.BACKGROUND);

export const getPrizesList = (limit: number): StoreItem[] =>
  getStoreItemList(limit, 'custom');

export const mockPurchases: PurchasedItem[] = [
  {
    itemImageUrl: placeholderImage,
    name: 'i-C',
    realPrice: random(1, 100),
    currencySymbol: '€',
    coins: random(1, 100),
    userImageUrl: placeholderImage,
    username: 'Lincoln Worker',
    purchaseReference: `${random(1, 100)}`,
    purchaseDate: getRandomNumber(),
    purchaseStatus: PurchaseStatus.ACQUIRED,
    itemOrigin: ItemOrigin.COLLECTABLE,
    prependSymbol: false,
    itemReference: `${random(1, 100)}`,
    category: 'item.type.avatar',
  },
  {
    itemImageUrl: placeholderImage,
    name: 'Bananabananabananabanana',
    realPrice: random(1, 100),
    currencySymbol: '€',
    coins: random(1, 100),
    userImageUrl: placeholderImage,
    username: 'Lincoln Worker',
    purchaseReference: `${random(1, 100)}`,
    purchaseDate: getRandomNumber(),
    purchaseStatus: PurchaseStatus.ACQUIRED,
    itemOrigin: ItemOrigin.COLLECTABLE,
    prependSymbol: false,
    itemReference: `${random(1, 100)}`,
    category: 'item.type.background',
  },
  {
    itemImageUrl: placeholderImage,
    name: 'Mate with a name that is too long to be here in store',
    realPrice: random(1, 100),
    currencySymbol: '€',
    coins: random(1, 100),
    userImageUrl: placeholderImage,
    username: 'Lincoln Worker',
    purchaseReference: `${random(1, 100)}`,
    purchaseDate: getRandomNumber(),
    purchaseStatus: PurchaseStatus.ACQUIRED,
    itemOrigin: ItemOrigin.PRIZE,
    prependSymbol: false,
    itemReference: `${random(1, 100)}`,
    category: 'Drinks',
  },
];

export const getPurchases = () => {
  return {
    metadata,
    results: mockPurchases,
  };
};

export const getStoreItems = (type?: string | null, limit?: string | null) => {
  const itemLimit = limit ? Number(limit) : 77;

  const results = () => {
    if (type === 'avatar') {
      return getAvatarList(itemLimit);
    }

    if (type === 'background') {
      return getBackgroundList(itemLimit);
    }

    return [
      ...getAvatarList(itemLimit),
      ...getBackgroundList(itemLimit),
      ...getPrizesList(itemLimit),
    ];
  };

  return {
    metadata,
    results: {
      storeItems: results(),
    },
  };
};

export const mockAvatarCategories = (): ApiResponse<CategorizedOption[]> => {
  return {
    metadata,
    results: [
      {
        tagName: 'category-1',
        tagReference: 'ref-1',
        storeItems: getAvatarList(5),
      },
      {
        tagName: 'category-2',
        tagReference: 'ref-2',
        storeItems: getAvatarList(5),
      },
    ],
  };
};

export const mockBackgroundCategories = (): ApiResponse<
  CategorizedOption[]
> => {
  return {
    metadata,
    results: [
      {
        tagName: 'category-bg-1',
        tagReference: 'ref-bg-1',
        storeItems: getBackgroundList(4),
      },
      {
        tagName: 'category-bg-2',
        tagReference: 'ref-bg-2',
        storeItems: getBackgroundList(4),
      },
    ],
  };
};

export const mockAvatars = (): ApiResponse<CollectablesResponse> => {
  return {
    metadata,
    results: {
      tagName: 'Avatars',
      numberOfItems: 10,
      numberOfPurchased: 5,
      items: getAvatarList(10),
    },
  };
};

export const mockBackgrounds = (): ApiResponse<CollectablesResponse> => {
  return {
    metadata,
    results: {
      tagName: 'Backgrounds',
      numberOfItems: 10,
      numberOfPurchased: 5,
      items: getBackgroundList(10),
    },
  };
};

export const mockPrizes = (): ApiResponse<StoreItem[]> => {
  return {
    metadata,
    results: getPrizesList(5),
  };
};
