import axios from '@/plugins/http/axios';
import { ApiResponse } from '@/shared/types/generic';
import {
  INotificationItem,
  NotificationCenterSource,
} from '@/shared/types/notificationCenter';

export default {
  getNotificationCenterItems(): Promise<ApiResponse<INotificationItem[]>> {
    return axios.get('/notification-center');
  },

  patchNotificationsAsRead(listOfNotifications: string[]): Promise<void> {
    return axios.patch('/notification-center/mark-as-read', {
      notificationReferences: listOfNotifications,
    });
  },

  postNotificationRequest(
    notificationType: NotificationCenterSource,
  ): Promise<void> {
    const body = {
      notificationType,
    };
    return axios.post('/notification', body);
  },
};
