import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "footer",
  class: "app-footer",
  "data-testid": "app-layout-footer"
}
const _hoisted_2 = { "data-testid": "app-layout-footer-nav" }
const _hoisted_3 = { class: "app-footer-link__icon" }

import { AppLayoutNavigationLink } from '@/shared/types/components';
import { computed } from 'vue';
import AppList from '@/components/app/AppList/AppList.vue';
import { useFeatures } from '@/plugins/commons';
import { Feature, Routes } from '@/shared/types/generic';
import IconChallengesLine from '@/assets/icons/icon-challenges-line.vue';
import IconChallengesFilled from '@/assets/icons/icon-challenges-filled.vue';
import IconProfileLine from '@/assets/icons/icon-profile-line.vue';
import IconProfileFilled from '@/assets/icons/icon-profile-filled.vue';
import IconStoreLine from '@/assets/icons/icon-store-line.vue';
import IconStoreFilled from '@/assets/icons/icon-store-filled.vue';
import IconLeaderboardLine from '@/assets/icons/icon-leaderboard-line.vue';
import IconLeaderboardFilled from '@/assets/icons/icon-leaderboard-filled.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AppFooter',
  setup(__props) {

const { isFeatureEnabled } = useFeatures();

const navigationLinks = computed<AppLayoutNavigationLink[]>(() => {
  const links: AppLayoutNavigationLink[] = [
    {
      to: Routes.CHALLENGES,
      icon: IconChallengesLine,
      activeIcon: IconChallengesFilled,
      testId: 'nav-games-link',
      id: 'vaibe-ftx__challenges',
    },
    {
      to: Routes.STORE,
      icon: IconStoreLine,
      activeIcon: IconStoreFilled,
      testId: 'nav-store-link',
      id: 'vaibe-ftx__store',
    },
  ];

  if (isFeatureEnabled(Feature.LEADERBOARD_OPERATOR)) {
    links.splice(1, 0, {
      to: '/leaderboard',
      icon: IconLeaderboardLine,
      activeIcon: IconLeaderboardFilled,
      testId: 'nav-leaderboards-link',
      id: 'vaibe-ftx__leaderboard',
    });
  }

  links.push({
    to: '/profile',
    icon: IconProfileLine,
    activeIcon: IconProfileFilled,
    testId: 'nav-profile-link',
    id: 'vaibe-ftx__profile',
  });

  return links;
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("nav", _hoisted_2, [
      _createVNode(AppList, {
        gap: "4px",
        "flex-wrap": "no-wrap",
        class: "app-footer-nav__list",
        items: navigationLinks.value
      }, {
        item: _withCtx(({ item }) => [
          _createVNode(_component_router_link, {
            id: item.id,
            class: "app-footer-link",
            "active-class": "app-footer-link--active",
            to: item.to,
            "data-testid": item.testId
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), {
                  alt: "menu-icon",
                  class: "app-footer-link__icon__empty"
                })),
                (_openBlock(), _createBlock(_resolveDynamicComponent(item.activeIcon), {
                  alt: "menu-icon",
                  class: "app-footer-link__icon__filled"
                }))
              ])
            ]),
            _: 2
          }, 1032, ["id", "to", "data-testid"])
        ]),
        _: 1
      }, 8, ["items"])
    ])
  ]))
}
}

})