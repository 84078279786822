import { IBase, Routes } from './generic';

export enum NotificationCenterSource {
  START_CHALLENGE = 'start_challenge',
  END_CHALLENGE = 'end_challenge',
  CANCELLATION_CHALLENGE = 'cancellation_challenge',
  START_SURVEY = 'start_survey',
  ON_GOING_SURVEY = 'on_going_survey',
  STORE_BALANCE = 'store_balance',
  RECEIVED_KUDOS = 'received_kudos',
  ACHIEVEMENT_RECEIVED = 'achievement_received',
  PROFILE = 'profile',
  PROFILE_AVATAR = 'profile_avatar',
  PROFILE_BACKGROUND = 'profile_backgroud',
}

export const NotificationCenterSourceMap: Record<string, string> = {
  START_CHALLENGE: 'start_challenge',
  END_CHALLENGE: 'end_challenge',
  CANCELLATION_CHALLENGE: 'cancellation_challenge',
  START_SURVEY: 'start_survey',
  ON_GOING_SURVEY: 'on_going_survey',
  STORE_BALANCE: 'store_balance',
  RECEIVED_KUDOS: 'received_kudos',
  ACHIEVEMENT_RECEIVED: 'achievement_received',
  PROFILE: 'profile',
  PROFILE_AVATAR: 'profile_avatar',
  PROFILE_BACKGROUND: 'profile_backgroud',
};

export enum NotificationCenterStatus {
  READ = 'READ',
  UNREAD = 'UNREAD',
}

export interface INotificationItem extends IBase {
  itemReference: string;
  source: NotificationCenterSource;
  action: Routes;
  icons: {
    active: string;
    disabled: string;
  };
  status: NotificationCenterStatus;
  createdAt: number;
}
