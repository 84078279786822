/* eslint-disable no-underscore-dangle */
import useTokenStore from '@/store/token/useTokenStore';
import { usePostHogConfig } from '@/plugins/commons';

export default function usePosHogConfig() {
  const tokenStore = useTokenStore();
  const { postHogConfig, postHogInit, postHogLoadToolbar } = usePostHogConfig();

  async function postHogInitialize() {
    postHogInit();
  }

  async function postHogSetup(): Promise<void> {
    await postHogConfig(tokenStore.getPostHogKey);
  }

  async function loadToolbarPostHog() {
    postHogLoadToolbar();
  }

  return { postHogSetup, postHogInitialize, loadToolbarPostHog };
}
