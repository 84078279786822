import {
  placeholderBackground,
  placeholderImage,
} from '@/shared/constants/icons';
import { getRandomNumber } from '@/plugins/commons';
import { ApiResponse, Locale } from '@/shared/types/generic';
import {
  UserProfileDetails,
  DetailedProfileUpdateRequest,
  CustomAvatarAsset,
  CustomAvatarAssetCategory,
  Inventory,
  Item,
  ProfileUpdateRequest,
  UserProfile,
  Avatar,
  Background,
  Activity,
  ActivityChallenge,
  ActivityMetric,
  ProfileSite,
  CollectableTypeName,
  LanguageCode,
} from '@/shared/types/profile';
import getRandomReference from '@/shared/helpers/getRandomReference/getRandomReference';

export const season = {
  leaderboard: {
    bannerImage: '/img/season_outer_space.svg',
    crownImage: '/img/position-crown-blue.svg',
    frameColor: '#FFC700',
    headerColor: '#FFC700',
    title: {
      name: 'vaibers',
      size: 18,
      color: '#99ffe6',
      isBold: true,
      isItalic: true,
      isUppercase: true,
    },
    subTitle: {
      name: 'from outer space',
      size: 18,
      color: '#99ffe6',
      isBold: true,
      isItalic: true,
      isUppercase: true,
    },
    description: {
      name: 'first season',
      size: 18,
      color: '#99ffe6',
      isBold: true,
      isItalic: true,
      isUppercase: true,
    },
  },
};

export const profile: UserProfile = {
  user: {
    termsConditions: true,
    privacyPolicy: true,
    changePassword: false,
    orgUserId: 'orgId',
    email: 'john_doe@vaibe.com',
    orgName: 'Vaibe',
    username: 'John Doe',
    userDescription: 'Lorem ipsum dolor sit amet',
  },
  userConfig: {
    userLanguage: Locale.US,
    language: Locale.US,
    deviceToken: '',
    showFtx: false,
    transactionCode: 2023,
    surveyPopUp: false,
  },
};

export const mockItem = (
  itemType: CollectableTypeName,
  id = getRandomNumber(1000, 1),
): Item => ({
  id,
  reference: String(id),
  name: '',
  description: '',
  url:
    itemType === CollectableTypeName.AVATAR
      ? placeholderImage
      : placeholderBackground,
  type: {
    reference: 'ref-',
    name:
      itemType === CollectableTypeName.AVATAR
        ? CollectableTypeName.AVATAR
        : CollectableTypeName.BACKGROUND,
  },
});

export const mockItems = (
  length: number,
  itemType: CollectableTypeName,
): Item[] => Array.from({ length }).map(() => mockItem(itemType));

export const avatars: Avatar[] = Array.from({ length: 10 }).map(
  (_, index: number) => mockItem(CollectableTypeName.AVATAR, index),
);

export const backgrounds: Background[] = Array.from({ length: 10 }).map(
  (_, index: number) => mockItem(CollectableTypeName.BACKGROUND, index + 10),
);

export const inventory: Inventory = [...avatars, ...backgrounds];

export const userProfileDetails: UserProfileDetails = {
  position: 1,
  points: 1250,
  coins: 987654321,
  joinedAt: 1623345600000,
  activeAvatar: avatars[0],
  activeBackground: backgrounds[0],
};

export function updateProfile(data: ProfileUpdateRequest) {
  profile.userConfig.showFtx = data.showFtx ?? profile.userConfig.showFtx;
}

export function updateDetailedProfile({
  backgroundId,
  avatarId,
  username,
}: DetailedProfileUpdateRequest) {
  if (backgroundId) {
    const newBackground = inventory.find((item) => item.id === backgroundId);

    if (newBackground) {
      userProfileDetails.activeBackground = newBackground;
    }
  }

  if (avatarId) {
    const newAvatar = inventory.find((item) => item.id === avatarId);

    if (newAvatar) {
      userProfileDetails.activeAvatar = newAvatar;
    }
  }

  if (username) {
    if (username.includes('error')) {
      throw new Error('10');
    }
    profile.user.username = username;
  }
}

export const mockProfileActivity = (): Activity => ({
  numberOfWins: 10,
  highestPlacement: 1,
  challengeParticipation: 10,
  rankBalance: {
    lastMonth: 4,
    currentMonth: 1,
  },
});

export const mockProfileActivityChallenges = (): ApiResponse<
  ActivityChallenge[]
> => {
  const results = Array.from({ length: 8 }).map(() => ({
    challengeName: `test challenge ${getRandomNumber(100)}`,
    startDate: 1623345600000,
    endDate: 1623345600000,
    units: 10,
    metricName: `test metric ${getRandomNumber(100)}`,
    categoryName: `test category ${getRandomNumber(100)}`,
    unitMeasureName: 'unitMeasure.quantity',
  }));

  return {
    metadata: {
      totalItems: results.length,
      lastUpdated: 1650290653000,
    },
    results,
  };
};

export const mockProfileActivityMetrics = (): ApiResponse<ActivityMetric[]> => {
  const results = Array.from({ length: 8 }).map(() => ({
    metricName: `test metric ${getRandomNumber(100)}`,
    lastUpdate: 1623345600000,
    units: 10,
    unitMeasureName: 'unitMeasure.quantity',
  }));

  return {
    metadata: {
      totalItems: results.length,
      lastUpdated: 1650290653000,
    },
    results,
  };
};

export const mockCustomAvatarAssetCategory = (): CustomAvatarAssetCategory => ({
  name: '',
  url: placeholderImage,
  reference: getRandomReference(),
  displayOrder: getRandomNumber(100),
});

export const mockCustomAvatarAsset = (
  categoryAssetReference: string,
): CustomAvatarAsset => ({
  name: '',
  url: placeholderImage,
  reference: getRandomReference(),
  displayOrder: getRandomNumber(100),
  categoryAssetReference,
});

export const mockProfileSite = (): ProfileSite => ({
  name: 'lisbon site',
  timezone: {
    ianaTimezone: 'UTC',
    utcOffset: '+000',
  },
});

export const mockLanguages = (): LanguageCode[] => [
  { code: 'en-US' },
  { code: 'pt-PT' },
  { code: 'de-DE' },
  { code: 'es-ES' },
];

export const handleFtx = () => {
  profile.userConfig.showFtx = false;
};
