/* eslint-disable camelcase */
import { ResponsePostHog } from '@/plugins/commons';
import axios from 'axios';
import { defineStore } from 'pinia';

interface State {
  token: string;
  refreshToken: string;
  exp: string;
  refreshRequest: Promise<void> | null;
  orgId: string;
  b2cId: string;
}

export default defineStore({
  id: 'token',

  persist: true,

  state: (): State => ({
    token: '',
    refreshToken: '',
    exp: '',
    refreshRequest: null,
    orgId: '',
    b2cId: '',
  }),

  actions: {
    setToken(token: string, refreshToken: string, exp: string): void {
      this.token = token;
      this.refreshToken = refreshToken;
      this.exp = exp;
    },

    decodeTokenGetOrgIdAndB2cId(token: string): {
      orgId: string;
      oid: string;
    } {
      const base64Url = token.split('.')[1];
      const base64 = base64Url
        ? base64Url.replace(/-/g, '+').replace(/_/g, '/')
        : '';
      const jsonPayload = base64
        ? decodeURIComponent(
            window
              .atob(base64)
              .split('')
              .map((c) => {
                return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
              })
              .join(''),
          )
        : '';

      const { extension_OrgId, oid } = jsonPayload
        ? JSON.parse(jsonPayload)
        : { extension_OrgId: '', oid: '' };
      const orgId = extension_OrgId;
      return { orgId, oid };
    },

    getPostHogKey(): Promise<ResponsePostHog> {
      const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/configs`;
      return axios.get(apiUrl, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${this.token}`,
        },
      });
    },

    async logInUser(username: string, password: string): Promise<void> {
      const bodyFormData = new FormData();

      bodyFormData.append('username', username);

      bodyFormData.append('password', password);

      bodyFormData.append('grant_type', 'password');

      bodyFormData.append('response_type', 'token id_token');

      bodyFormData.append('client_id', process.env.VUE_APP_AD_CLIENT_ID || '');

      bodyFormData.append(
        'scope',
        `openid profile offline_access https://${process.env.VUE_APP_AD_RESOURCE_NAME}/${process.env.VUE_APP_AD_CLIENT_ID}/operator.read`,
      );

      return axios({
        method: 'post',
        data: bodyFormData,
        headers: { 'Content-Type': 'multipart/form-data' },
        url: `https://${process.env.VUE_APP_AD_TENANT}.b2clogin.com/${process.env.VUE_APP_AD_RESOURCE_NAME}/oauth2/v2.0/token?p=${process.env.VUE_APP_AD_SIGN_IN_POLICY}`,
      }).then((resp) => {
        this.token = resp.data.access_token;
        this.refreshToken = resp.data.refresh_token;
        this.exp = resp.data.expires_in;

        const { orgId, oid } = this.decodeTokenGetOrgIdAndB2cId(this.token);

        this.orgId = orgId;
        this.b2cId = oid;
      });
    },

    async refreshUserToken(): Promise<void> {
      if (this.refreshRequest !== null) {
        return this.refreshRequest;
      }

      const bodyFormData = new FormData();

      bodyFormData.append('client_id', process.env.VUE_APP_AD_CLIENT_ID || '');

      bodyFormData.append(
        'scope',
        `openid profile offline_access https://${process.env.VUE_APP_AD_RESOURCE_NAME}/${process.env.VUE_APP_AD_CLIENT_ID}/operator.read`,
      );

      bodyFormData.append('refresh_token', this.refreshToken);

      bodyFormData.append('grant_type', 'refresh_token');

      bodyFormData.append('response_type', 'token id_token');

      const promise = axios({
        method: 'post',
        data: bodyFormData,
        headers: { 'Content-Type': 'multipart/form-data' },
        url: `https://${process.env.VUE_APP_AD_TENANT}.b2clogin.com/${process.env.VUE_APP_AD_RESOURCE_NAME}/oauth2/v2.0/token?p=${process.env.VUE_APP_AD_SIGN_IN_POLICY}`,
      })
        .then((response) => {
          this.token = response.data.access_token;
          this.refreshToken = response.data.refresh_token;
          this.exp = response.data.expires_in;
        })
        .finally(() => {
          this.refreshRequest = null;
        });

      this.refreshRequest = promise;

      return promise;
    },
  },
});
