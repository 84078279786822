<template>
  <section
    :class="[
      'app-header__container',
      { 'app-header__container--thin': isType(AppHeaderType.THIN) },
    ]"
  >
    <AppProfileHeader
      v-if="isType(AppHeaderType.PROFILE)"
      :title="title"
      @go-back="goBack"
    />
    <section v-else class="app-header">
      <AppButton
        v-if="isType(AppHeaderType.THIN)"
        type="empty"
        text-color="black"
        class="app-header__back-button"
        @click="goBack"
      >
        <VueIcon :path="mdiArrowLeft" type="mdi" />
      </AppButton>
      <h1 data-testid="app-header-title" class="app-header__title">
        {{ t(title) }}
      </h1>
      <div v-if="isType(AppHeaderType.BASE)" class="app-header__trailing">
        <router-link
          v-if="isFeatureEnabled(Feature.NOTIFICATION_CENTER)"
          to="/notification-center"
          class="app-header__links"
        >
          <div class="app-header__notifications-icon-container">
            <IconNotifications id="vaibe-ftx__notification-center" />
            <span
              v-if="!notificationCenterStore.allRead"
              class="app-header__red-dot"
            ></span>
          </div>
        </router-link>

        <router-link to="/more" class="app-header__links">
          <IconThreeDots id="vaibe-ftx__more" />
        </router-link>
      </div>
    </section>
    <div class="app-header__trailing__target"></div>
    <AppTabs
      v-if="hasTabs"
      class="app-header__tabs"
      :tabs="tabs"
      :outlined="true"
      replace-route
    />
    <div class="app-header__bottom-target"></div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mdiArrowLeft } from '@mdi/js';
import VueIcon from '@jamescoyle/vue-icon';
import { AppTab, AppHeaderType } from '@/shared/types/components';
import { Feature } from '@/shared/types/generic';
import { useFeatures } from '@/plugins/commons';
import { mapStores } from 'pinia';
import IconThreeDots from '@/assets/icons/icon-three-dots.vue';
import IconNotifications from '@/assets/icons/icon-notifications.vue';
import useSurveysStore from '@/store/surveys/useSurveysStore';
import useNotificationCenterStore from '@/store/notificationCenter/useNotificationCenterStore';
import { useI18n } from 'vue-i18n';
import AppButton from '../AppButton/AppButton.vue';
import AppTabs from '../AppTabs/AppTabs.vue';
import AppProfileHeader from '../AppProfileHeader/AppProfileHeader.vue';

export default defineComponent({
  name: 'AppHeader',

  components: {
    VueIcon,
    AppTabs,
    AppButton,
    IconThreeDots,
    IconNotifications,
    AppProfileHeader,
  },

  props: {
    isSecondaryPage: {
      type: Boolean,
      default: false,
    },
    isPrimaryPage: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const { t } = useI18n();
    const { isFeatureEnabled } = useFeatures();
    const notificationCenterStore = useNotificationCenterStore();

    return { t, isFeatureEnabled, notificationCenterStore };
  },

  data() {
    return {
      mdiArrowLeft,
      AppHeaderType,
      Feature,
    };
  },

  computed: {
    ...mapStores(useSurveysStore),

    tabs(): AppTab[] {
      return (this.$route.meta.tabs as AppTab[]) ?? [];
    },

    hasTabs(): boolean {
      return (
        this.tabs !== undefined &&
        this.tabs.length > 0 &&
        this.tabs.some((tab) => tab.path === this.$route.path)
      );
    },

    title(): string {
      return `nav.${this.$route.matched[0].name?.toString()}`;
    },

    type(): AppHeaderType {
      return (
        (this.$route.meta.headerType as AppHeaderType) || AppHeaderType.BASE
      );
    },
  },

  methods: {
    isType(headerType: AppHeaderType): boolean {
      return this.type === headerType;
    },

    goBack(): void {
      this.$router.back();
    },
  },
});
</script>
<style lang="scss">
.app-header__container {
  flex: 0;
  background: $white;
  border-radius: 0px 0px 16px 16px;
}

.app-header {
  z-index: 4;
  display: flex;
  align-items: center;
  padding: 16px 16px 8px 16px;
  justify-content: space-between;
}

.app-header__container--thin .app-header {
  padding: 16px;
  gap: 16px;
  justify-content: unset;
}

.app-header__title {
  margin-bottom: 0;
}

.app-header__container--thin .app-header .app-header__title {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

.app-header__back-button {
  width: 24px;
  height: 24px;
  --padding-end: 0;
  --padding-start: 0;
}

.app-header__trailing,
.app-header__trailing__target {
  gap: 4px;
  display: flex;
  align-items: center;
}

.app-header__tabs {
  padding: 8px 16px !important;
}

.app-header__links {
  display: inline-flex;
}

.app-header__notifications-icon-container {
  position: relative;
  display: flex;
}

.app-header__red-dot {
  width: 12px;
  height: 12px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  right: 5px;
  border: 2px solid $white;
}
</style>
