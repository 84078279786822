<template>
  <div
    data-testid="app-layout"
    :class="['app-layout', `app-layout--${layoutType}`]"
  >
    <template v-if="showHeader">
      <div class="app-layout__gradient" data-testid="app-layout-gradient" />
      <AppHeader />
    </template>
    <div
      id="app-layout-wrapper"
      class="app-layout-wrapper"
      data-testid="app-layout-wrapper"
    >
      <slot />
    </div>
    <AppFooter v-if="showFooter" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { mdiMenu } from '@mdi/js';
import useSurveysStore from '@/store/surveys/useSurveysStore';
import { Feature } from '@/shared/types/generic';
import AppHeader from '@/components/app/AppHeader/AppHeader.vue';
import AppFooter from '@/components/app/AppFooter/AppFooter.vue';
import { useFeatures } from '@/plugins/commons';
import { AppLayoutType } from '@/shared/types/components';
import { useRoute } from 'vue-router';
import usePostHogEvents from '@/composables/usePostHogEvents/usePostHogEvents';
import { PostHogEvents } from '@/shared/types/posthog';
import usePushNotificationsStore from '@/store/pushNotifications/usePushNotificationsStore';
import useNotificationCenterStore from '@/store/notificationCenter/useNotificationCenterStore';

export default defineComponent({
  name: 'AppLayout',

  components: {
    AppHeader,
    AppFooter,
  },

  setup() {
    const { isFeatureEnabled } = useFeatures();
    const route = useRoute();
    const { postHogEvent } = usePostHogEvents();
    const pushNotificationStore = usePushNotificationsStore();
    const notificationCenterStore = useNotificationCenterStore();

    return {
      isFeatureEnabled,
      route,
      postHogEvent,
      pushNotificationStore,
      notificationCenterStore,
    };
  },

  data() {
    return {
      mdiMenu,
      Feature,
      AppLayoutType,
    };
  },

  computed: {
    ...mapStores(useSurveysStore),

    layoutType(): AppLayoutType {
      return (
        (this.$route.meta.layoutType as AppLayoutType) || AppLayoutType.BASE
      );
    },

    showHeader(): boolean {
      if (
        this.layoutType === AppLayoutType.EMPTY ||
        this.layoutType === AppLayoutType.HEADERLESS
      ) {
        return false;
      }

      return true;
    },

    showFooter(): boolean {
      if (
        this.layoutType === AppLayoutType.EMPTY ||
        this.layoutType === AppLayoutType.FOOTERLESS
      ) {
        return false;
      }

      return true;
    },
  },

  watch: {
    'route.fullPath': {
      handler(newPath) {
        if (newPath.includes('pushNotificationOpened')) {
          this.postHogEvent(PostHogEvents.NOTIFICATION_OPENED, {
            action: this.pushNotificationStore.state.action,
            topicKey: this.pushNotificationStore.state.topicKey,
            itemReference: this.pushNotificationStore.state.itemReference,
          });

          this.notificationCenterStore.updateNotificationsAsRead([
            this.pushNotificationStore.state.notificationReference,
          ]);
        }
      },
    },
  },

  mounted() {
    if (this.route.fullPath.includes('pushNotificationOpened')) {
      this.postHogEvent(PostHogEvents.NOTIFICATION_OPENED, {
        action: this.pushNotificationStore.state.action,
        topicKey: this.pushNotificationStore.state.topicKey,
        itemReference: this.pushNotificationStore.state.itemReference,
      });

      this.notificationCenterStore.updateNotificationsAsRead([
        this.pushNotificationStore.state.notificationReference,
      ]);
    }
  },

  methods: {
    isLayoutType(layoutType: AppLayoutType): boolean {
      return this.layoutType === layoutType;
    },
  },
});
</script>

<style scoped lang="scss">
.app-layout {
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: $gray-25;
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
}

.app-layout--EMPTY {
  margin-bottom: unset;
}

.app-layout-wrapper {
  flex: 1;
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
}

.app-layout--BASE .app-layout-wrapper {
  padding: 16px;
}

.app-layout__gradient {
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  height: $top-gradient-height;
  background: $vaibe-gradient-dark;
}
</style>
